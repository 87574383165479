<template>
    <div class="contaner">
       <Alert :message='marketsError' type="alert-danger" v-if="marketsError" />
        <div class="row">
          <div class="col-12">
            <a href="#" class="btn btn-success  my-3" style="float:right" @click="removeMessage"
                data-toggle="modal" data-target="#addMarket">
                <i class="fas fa-pen-alt"></i>Add Market 
            </a>
          </div>
        </div>

        <div class="card shadow-sm mb-5">
          <div class="card-header">
        <h3 class="my-2 text-secondary" style="font-size:1em;">Markets</h3>
        </div>
        <div class="card-body">
        <div class="table-responsive">
            <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            >
            <thead>
                <tr>
                <th>Market Name</th>
                <th>Actions</th>
                
                </tr>
            </thead>
            <tbody>
                <tr  v-for="(market, key) in markets" :key="key">
                <td>{{market.name}}</td>
                <td>
                    <button @click="updateForm({...market},key)" class="btn btn-success mr-3"
                        data-toggle="modal" data-target="#editMarket">

                        <i class="far fa-edit"></i>edit
                    </button>
                    <button @click="getDetails(market.id,market.name,key)" class="btn btn-danger"
                        data-toggle="modal" data-target="#deleteMarket">
                        <i class="fas fa-trash">   </i> delete
                    </button>
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <th>Name</th>
                <th>Actions</th>
                </tr>
            </tfoot>
            </table>
        </div>
        </div>
        </div>
      <!--add market modal content -->
      <div class="modal fade" id="addMarket" tabindex="-1" aria-labelledby="add market" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Market </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
               <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                 <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
              <form @submit.prevent="addMarket">
                  <div class="form-row mb-2">
                    <label class="col-sm-4 col-form-label" for="exampleInputEmail1">Market Name</label>
                    <div class="col-md-8">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                         v-model="name" placeholder="Enter market Name" required>
                    </div>
                  </div>
                  <!--  -->
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Health and Safety Registration Type/Types</label>
                     <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(healthAndSafety,key) in healthAndSafetyType " :key="key">
                           <input required type="text" class="form-control" v-model="healthAndSafety.health_and_safety_registeration_types">
                         </div>
                         <div class="col-4 my-2 d-flex justify-content-end">
                          <button @click="addHealthAndSafety" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="healthAndSafetyType.length > 1" @click="deleteHealthAndSafety" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Standards Registration Type/Types</label>
                    <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(standardType,key) in standardRegisterationType" :key="key">
                           <input required type="text" class="form-control" v-model="standardType.standards_registeration_types">
                         </div>
                         <div class="col-4 d-flex justify-content-end my-2">
                          <button @click="addStandardRegisterationType" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="standardRegisterationType.length > 1" @click="deleteStandardRegisterationType" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Certification Type/Types</label>
                    <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(certification,key) in certificationType" :key="key">
                           <input required type="text" class="form-control" v-model="certification.certification_types">
                         </div>
                         <div class="col-4 my-2 d-flex justify-content-end">
                          <button @click="addCertificationType" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="certificationType.length > 1" @click="deleteCertificationType" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <button type="submit" class="btn btn-danger my-2" style="float:right">
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                    Add Market
                  </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end of add modal content -->
      <!-- update modal content -->
      <div class="modal fade" id="editMarket" tabindex="-1" aria-labelledby="edit-market" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Market </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
               <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                 <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
              <form @submit.prevent="updateMarket">
                  <div class="form-row mb-2">
                    <label class="col-sm-4 col-form-label" for="exampleInputEmail1">Market Name</label>
                    <div class="col-md-8">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                         v-model="name" placeholder="Enter market Name" required>
                    </div>
                  </div>
                  <!--  -->
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Health and Safety Registration Type/Types</label>
                     <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(healthAndSafety,key) in healthAndSafetyType " :key="key">
                           <input required type="text" class="form-control" v-model="healthAndSafety.health_and_safety_registeration_types">
                         </div>
                         <div class="col-4 my-2 d-flex justify-content-end">
                          <button @click="addHealthAndSafety" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="healthAndSafetyType.length > 1" @click="deleteHealthAndSafety" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Standards Registration Type/Types</label>
                    <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(standardType,key) in standardRegisterationType" :key="key">
                           <input required type="text" class="form-control" v-model="standardType.standards_registeration_types">
                         </div>
                         <div class="col-4 d-flex justify-content-end my-2">
                          <button @click="addStandardRegisterationType" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="standardRegisterationType.length > 1" @click="deleteStandardRegisterationType" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <div class="form-row">
                    <label class="col-sm-4 col-form-label">Certification Type/Types</label>
                    <div class="col-md-8">
                       <div class="row">
                         <div class="col-8 my-2" v-for="(certification,key) in certificationType" :key="key">
                           <input required type="text" class="form-control" v-model="certification.certification_types">
                         </div>
                         <div class="col-4 my-2 d-flex justify-content-end">
                          <button @click="addCertificationType" type="button" class="btn btn-success mx-2">add</button>
                          <button v-if="certificationType.length > 1" @click="deleteCertificationType" type="button" class="btn btn-danger">delete</button>
                         </div>
                       </div>
                     </div>
                  </div>
                  <button type="submit" class="btn btn-danger my-2" style="float:right">
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                     Edit Market
                  </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal content -->
      <!-- delete modal  content -->
      <div class="modal fade" id="deleteMarket" tabindex="-1" aria-labelledby="Delete Market" aria-hidden="true">
        <div class="modal-dialog">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirm Delete</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-danger" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
                <button :disabled='success' style="float:right" class="btn btn-danger" @click="deleteMarket">delete</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of delete modal content -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
const $ = require('jquery')
export default {
    name:'Markets',
    data() {
      return {
        name:null,
        success:null,
        addError:null,
        marketsError:null,
        marketName:null,
        marketId:null,
        key:null,
        error:null,
        loading:false,
        // Dynamic form types
        healthAndSafetyType: [
          {
            health_and_safety_registeration_types:''
          }
        ],
        standardRegisterationType:[
          {
            standards_registeration_types:''
          }
        ],
        certificationType: [
          {
            certification_types:''
          }
        ]
      }
    },
    methods: {
          updateForm(market,key){
            this.error = null
            this.success = null
            const certification = []
            const healthAndSafety = []
            const standard = []

              for(let i = 0; i < market.certification_types.length; i++ ) {
                certification.push({
                  certification_types:market.certification_types[i]
                })
              }
              for(let i = 0; i < market.health_and_safety_registeration_types.length; i++ ) {
                healthAndSafety.push({
                  health_and_safety_registeration_types:market.health_and_safety_registeration_types[i]
                })
              }
              for(let i = 0; i < market.standards_registeration_types.length; i++ ) {
                standard.push({
                  standards_registeration_types:market.standards_registeration_types[i]
                })
              }
              // populate the form with the new data 
              this.marketId = market.id
              this.name = market.name
              this.healthAndSafetyType = healthAndSafety
              this.standardRegisterationType = standard
              this.certificationType = certification 

            this.key = key
          },
          addCertificationType(){
            this.certificationType.push({
              certification_types:'',
            })
          },
          deleteCertificationType(){
            if(this.certificationType.length === 1) return
              this.certificationType.pop()
          },
          addStandardRegisterationType(){
            this.standardRegisterationType.push({
              standards_registeration_types:''
            })
          },
          deleteStandardRegisterationType(){
            if(this.standardRegisterationType.length === 1) return
            this.standardRegisterationType.pop()
          },
          addHealthAndSafety() {
            this.healthAndSafetyType.push({
              health_and_safety_registeration_types:''
            })
          },
          deleteHealthAndSafety() {
            if(this.healthAndSafetyType.length === 1) return 
              this.healthAndSafetyType.pop()
          },
          removeMessage() {
            this.error = null
            this.success = null
          },
          getDetails(id,name,key) {
            // remove previous error messages
            this.removeMessage()
            this.error = null
            this.success = null
            this.marketName = name
            this.marketId = id
            this.key = key
          },
         addMarket() {
           this.error = null
           this.success = null
           this.loading = true

           const health_and_safety_registeration_types = []
           const standards_registeration_types = []
           const certification_types = []
           this.certificationType.forEach(element => certification_types.push(element.certification_types))
           this.standardRegisterationType.forEach(element => standards_registeration_types.push(element.standards_registeration_types))
           this.healthAndSafetyType.forEach(element => health_and_safety_registeration_types.push(element.health_and_safety_registeration_types))

          const data =  {
              "name": this.name,
              "health_and_safety_registeration_types": health_and_safety_registeration_types,
              "standards_registeration_types" : standards_registeration_types,
              "certification_types": certification_types
          }

          this.$store.dispatch('addMarket',data)
            .then(response => {
                this.loading = false
                this.success = 'Market created successfully.'
                // reset the form to after the submission
                this.name = null
                this.standardRegisterationType = [{
                  standards_registeration_types:''
                }]
                this.certificationType = [{
                  certification_types:''
                }]
                this.healthAndSafetyType = [{
                  health_and_safety_registeration_types:''
                }]
            }).catch(err => {
              if(err?.response?.data === undefined) {
                this.loading = false
                this.addError = 'Network error.'
              }
            })
          console.log(data)
        },
        getMarkets() {
          this.$store.dispatch('getAllMarkets')
            .then(response => setTimeout(function(){$('#dataTable').DataTable();}, 5))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.marketsError = 'Network Error.'
              }
            })
        },
        deleteMarket() {
          this.error = null
          this.success = null
          const data = {
             id:this.marketId,
             key:this.key,
          }
          this.$store.dispatch('deleteMarket',data)
            .then(response => {
               this.success = 'Delete success.'
            }).catch(err => {
              if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
              }
            })
        },
        updateMarket() {
           this.loading = true

           const health_and_safety_registeration_types = []
           const standards_registeration_types = []
           const certification_types = []
           this.certificationType.forEach(element => certification_types.push(element.certification_types))
           this.standardRegisterationType.forEach(element => standards_registeration_types.push(element.standards_registeration_types))
           this.healthAndSafetyType.forEach(element => health_and_safety_registeration_types.push(element.health_and_safety_registeration_types))

          const market =  {
              "name": this.name,
              "health_and_safety_registeration_types": health_and_safety_registeration_types,
              "standards_registeration_types" : standards_registeration_types,
              "certification_types": certification_types
          }
          const data = {
             id:this.marketId,
             key:this.key,
             market:market
          }
          console.log('data', data)

          this.$store.dispatch('updateMarket',data)
            .then(response => {
               this.loading = false
               this.success = 'Update success.'
            }).catch(err => {
              if(err?.response?.data === undefined) {
                this.loading = false
                this.error = 'Network Error.'
              }
            })
        }
    },
    computed: { ...mapGetters(["markets"]) },
    created() {
          this.getMarkets()
          
        },
}
</script>
<style scoped>
  button, table , a{
    font-size: .9em;
  }
</style>